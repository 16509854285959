import {SITE_LANGUAGE} from "../utilities/Constants";
import {BattingStylesEN, BattingStylesHI, BowlingStylesEN, BowlingStylesHI, PlayerHittingRanks, PlayerHittingRanksHI, PlayingRolesEN, PlayingRolesHI} from "../utilities/Enums";
import * as PubNubService from "../services/PubNubService";

export function formatDate(dateString, timeOnly, short) {
   if (dateString) {
      const language = localStorage.getItem("language") === 'en' ? "en-US" : "hi-IN";
      const date = new Date(dateString);
      const options = {
         month: "long", day: "numeric", year: "numeric",
      };
      const formattedDate = date.toLocaleDateString(language, options);
      if (formattedDate !== "Invalid Date" && !timeOnly) {
         return formattedDate;
      } else if (timeOnly) {
         let option;
         if (short) {
            option = {
               month: "short", day: "numeric",
            };
         } else {
            option = {
               month: "long", day: "numeric",
            };
         }
         return date.toLocaleDateString(language, option);
      } else {
         return "---";
      }
   } else {
      return "---"
   }
}

export function formatTime(dateString) {
   if (dateString) {
      const date = new Date(dateString);
      const options = {
         hour: "numeric", minute: "numeric", hour12: true,
      };
      const formattedTime = date.toLocaleTimeString("en-US", options);
      if (formattedTime !== "Invalid Date") {
         return formattedTime;
      } else {
         return "---";
      }
   } else {
      return '---';
   }
}

//fast execution for array check for id
export function isExistChecker(array, id) {

   const idStore = {};

   array?.forEach((element) => {
      idStore[element?.id] = element;
   })

   id = parseInt(id);

   return !!idStore[id];

}

export function authRedirectHandler(navigate) {
   const redirectPath = sessionStorage.getItem('redirectPath');
   if (redirectPath === undefined || redirectPath === null) {
      return navigate("/")
   } else {
      return navigate(redirectPath)
   }
}

export const pubnubMatchesTokenValidator = async () => {
   try {
      PubNubService.tokenValidation(localStorage.getItem("pubnubToken"), localStorage.getItem("uuid")).then(response => {
         if (response.apiStatus !== true) {
            localStorage.removeItem("pubnubToken")
         }

      });

   } catch (error) {
      console.error("Error:", error);
   }
}

export const pubnubLiveTokenValidator = async (userName) => {
   try {
      PubNubService.tokenValidation(localStorage.getItem("pubnubLiveToken"), userName)
         .then(response => {
            if (response.apiStatus !== true) {

               localStorage.removeItem("pubnubLiveToken")
            }

         });

   } catch (error) {
      console.error("Error:", error);
   }
}

export function playerRoleStyleController(role, pageLang) {

   if (pageLang === SITE_LANGUAGE.ENGLISH) {
      if (Object.prototype.hasOwnProperty.call(BowlingStylesEN, role)) {
         return BowlingStylesEN[role];
      } else if (Object.prototype.hasOwnProperty.call(BattingStylesEN, role)) {
         return BattingStylesEN[role];
      } else if (Object.prototype.hasOwnProperty.call(PlayingRolesEN, role)) {
         return PlayingRolesEN[role];
      } else {
         return "N/A"
      }
   } else if (pageLang === SITE_LANGUAGE.HINDI) {
      if (Object.prototype.hasOwnProperty.call(BowlingStylesHI, role)) {
         return BowlingStylesHI[role];
      } else if (Object.prototype.hasOwnProperty.call(BattingStylesHI, role)) {
         return BattingStylesHI[role];
      } else if (Object.prototype.hasOwnProperty.call(PlayingRolesHI, role)) {
         return PlayingRolesHI[role];
      } else {
         return "N/A"
      }
   }
}

export function getPlayerHittingRank(percentage, pageLang = SITE_LANGUAGE.ENGLISH) {
   let RANKS = PlayerHittingRanks;

   if (pageLang === SITE_LANGUAGE.HINDI) {
      RANKS = PlayerHittingRanksHI;
   }

   if (percentage >= 80 && percentage <= 100) {
      return RANKS.LEGEND;
   } else if (percentage >= 60 && percentage < 80) {
      return RANKS.CHAMPION;
   } else if (percentage >= 40 && percentage < 60) {
      return RANKS.SKILLED;
   } else if (percentage >= 20 && percentage < 40) {
      return RANKS.NOVICE;
   } else if (percentage >= 0 && percentage < 20) {
      return RANKS.ROOKIE;
   } else {
      return 'Invalid Rank';
   }
}

export const getBallActionOutcome = (action) => {
   switch (action) {
      case 'NO_RUNS':
         return '0';
      case 'ONE_RUN':
         return '1';
      case 'TWO_RUNS':
         return '2';
      case 'THREE_RUNS':
         return '3';
      case 'FOUR_RUNS':
         return '4';
      case 'SIX_RUNS':
         return '6';
      case 'OUT':
         return 'W';
      case 'WIDE':
         return 'WB';
      default:
         return '-';
   }
}
