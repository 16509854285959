export const PlayingRolesEN = {
   OPENING_BOWLER: "OPENING-BOWLER",
   BOWLER: "BOWLER",
   MIDDLE_ORDER_BATSMAN: "MIDDLE-ORDER-BATSMAN",
   OPENING_BATSMAN: "OPENING-BATSMAN",
   BATSMAN: "BATSMAN",
   "ALL-ROUNDER": "ALL-ROUNDER",
};

export const PlayingRolesHI = {
   OPENING_BOWLER: "ओपनिंग बॉलर",
   BOWLER: "गेंदबाज",
   MIDDLE_ORDER_BATSMAN: "मध्यक्रम के बल्लेबाज",
   OPENING_BATSMAN: "ओपनिंग बल्लेबाज़",
   BATSMAN: "बल्लेबाज",
   "ALL-ROUNDER": "आल राउंडर",
};

export const BowlingStylesEN = {
   RIGHT_ARM_FAST: "RIGHT ARM, FAST",
   RIGHT_ARM_FAST_MEDIUM: "RIGHT ARM, FAST MEDIUM",
   RIGHT_ARM_MEDIUM_FAST: "RIGHT ARM, MEDIUM FAST",
   RIGHT_ARM_MEDIUM: "RIGHT ARM, MEDIUM",
   RIGHT_ARM_MEDIUM_SLOW: "RIGHT ARM, MEDIUM SLOW",
   RIGHT_ARM_SLOW_MEDIUM: "RIGHT ARM, SLOW MEDIUM",
   RIGHT_ARM_SLOW: "RIGHT ARM, SLOW",
   LEFT_ARM_FAST: "LEFT ARM, FAST",
   LEFT_ARM_FAST_MEDIUM: "LEFT ARM, FAST MEDIUM",
   LEFT_ARM_MEDIUM_FAST: "LEFT ARM, MEDIUM FAST",
   LEFT_ARM_MEDIUM: "LEFT ARM, MEDIUM",
   LEFT_ARM_MEDIUM_SLOW: "LEFT ARM, MEDIUM SLOW",
   LEFT_ARM_SLOW_MEDIUM: "LEFT ARM, SLOW MEDIUM",
   LEFT_ARM_SLOW: "LEFT ARM, SLOW",
   RIGHT_ARM_OFF_BREAK: "RIGHT ARM, OFF BREAK",
   RIGHT_ARM_LEG_BREAK: "RIGHT ARM, LEG BREAK",
   RIGHT_ARM_LEG_BREAK_GOOGLY: "RIGHT ARM, LEG BREAK GOOGLY",
   LEFT_ARM_ORTHODOX: "LEFT ARM, ORTHODOX",
   LEFT_ARM_WRIST_SPIN: "LEFT ARM, WRIST SPIN",
   LEFT_ARM_GOOGLY: "LEFT ARM, GOOGLY",
   MEDIUM_FACE: "MEDIUM-FACE"
};

export const BowlingStylesHI = {
   RIGHT_ARM_FAST: "दाहिना हाथ, तेज़",
   RIGHT_ARM_FAST_MEDIUM: "दाहिना हाथ, तेज़ मध्यम",
   RIGHT_ARM_MEDIUM_FAST: "दाहिना हाथ, मध्यम तेज़",
   RIGHT_ARM_MEDIUM: "दाहिना हाथ, मध्यम",
   RIGHT_ARM_MEDIUM_SLOW: "दाहिना हाथ, मध्यम धीमा",
   RIGHT_ARM_SLOW_MEDIUM: "दाहिना हाथ, धीमा माध्यम",
   RIGHT_ARM_SLOW: "दाहिना हाथ, धीमा",
   LEFT_ARM_FAST: "बायां हाथ, तेज",
   LEFT_ARM_FAST_MEDIUM: "बायां हाथ, तेज़ मध्यम",
   LEFT_ARM_MEDIUM_FAST: "बायां हाथ, मध्यम तेज",
   LEFT_ARM_MEDIUM: "बायां हाथ, मध्यम",
   LEFT_ARM_MEDIUM_SLOW: "बायां हाथ, मध्यम धीमा",
   LEFT_ARM_SLOW_MEDIUM: "बायां हाथ, धीमा माध्यम",
   LEFT_ARM_SLOW: "बायां हाथ, धीमा",
   RIGHT_ARM_OFF_BREAK: "दाहिना हाथ, ऑफ ब्रेक",
   RIGHT_ARM_LEG_BREAK: "दाहिना हाथ, पैर टूट गया",
   RIGHT_ARM_LEG_BREAK_GOOGLY: "दाहिना हाथ, पैर ब्रेक गुगली",
   LEFT_ARM_ORTHODOX: "बायां हाथ, रूढ़िवादी",
   LEFT_ARM_WRIST_SPIN: "बायां हाथ, कलाई घूमना",
   LEFT_ARM_GOOGLY: "बायां हाथ, गुगली",
   MEDIUM_FACE: "मध्यम चेहरा"
};

export const BattingStylesEN = {
   RIGHT_HAND: "RIGHT-HANDER",
   LEFT_HAND: "LEFT-HANDER"
};

export const BattingStylesHI = {
   RIGHT_HAND: "दाएँ हाथ वाला",
   LEFT_HAND: "बायां हाथ"
};

export const PlayerHittingRanks = {
   ROOKIE: "ROOKIE",
   NOVICE: "NOVICE",
   SKILLED: "SKILLED",
   CHAMPION: "CHAMPION",
   LEGEND: "LEGEND"
}

export const PlayerHittingRanksHI = {
   ROOKIE: "धोखेबाज़",
   NOVICE: "नौसिखिए",
   SKILLED: "कुशल",
   CHAMPION: "चैंपियन",
   LEGEND: "दंतकथा"
}
