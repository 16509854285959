export const AUTHENTICATION_TOASTS = Object.freeze({
   INVALID_FACEBOOK_LOGIN: 'Invalid Facebook Login',
   INVALID_GOOGLE_LOGIN: 'Invalid Google Login',
   ERROR_OCCURRED_GETTING_DESIGN_TYPES_TRY_RELOADING_THE_PAGE: 'Error Occurred When Getting User Followings. Please Try Again.',
   ASSET_UPLOAD_FAILED_PLEASE_TRY_AGAIN: 'Asset Upload Failed. Please Try Again.',
   ERROR_CHECKING_EMAIL_AVAILABILITY: "Email Checking Error. Please Try Again",
   ERROR_OCCURRED_WHILE_LOGIN: 'Error Occurred While Logging In. Please Try Again.',
   ERROR_OCCURRED_SENDING_CODE: "Error Occurred While Sending Secret Code. Please Try Again.",
   TOURNAMENT_IS_BLOCKED: "Tournament is blocked for your region."
})
