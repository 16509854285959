import {ApiEndpoints} from "./ApiConstants";
import AxiosInstance from "../helpers/AxiosInstance";

export const requestAccessTokenMatch = async (userName, matchId, authToken) => {
   try {
      const response = await AxiosInstance().post(ApiEndpoints.GET_MATCH_TOKEN, {
         userName: userName,
         matchId: matchId
      }, {
         headers: {
            "AuthToken": authToken
         }
      });
      return response.data.token;
   } catch (error) {
      return error.response;
   }
};

export const tokenValidation = async (token, userName) => {
   try {
      if (!token || !userName) {
         return false
      }
      const response = await AxiosInstance().put(ApiEndpoints.CHECK_MATCHES_TOKEN, {
         userName: userName
      }, {
         headers: {
            "AuthToken": token
         }
      });
      return response.data;
   } catch (error) {
      return error.response;
   }
}

export const requestAccessTokenMatches = async (userName, authToken) => {
   try {
      const response = await AxiosInstance().post(ApiEndpoints.GET_MATCHES_TOKEN, {
         userName: userName
      }, {
         headers: {
            "AuthToken": authToken
         }
      });
      return response.data.token;
   } catch (error) {
      return error.response;
   }
};
