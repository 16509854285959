export const TYPE = {
   TEAM: 'TEAM',
   PLAYER: 'PLAYER',
   MATCHUP: 'MATCHUP',
   REALTIME: 'REALTIME'
}

export const PERFORMANCE_OPTIONS = (STREAM = 1) => ({
   REALTIME: [
      {
         name: 'Batters',
         url: ''
      },
      {
         name: 'Bowlers',
         url: ''
      },
      {
         name: 'All Rounders',
         url: ''
      }
   ],

   PLAYER: [
      {
         name: 'Batter Recent Form',
         url: `https://public.tableau.com/views/M3ta11-btbazrsheets-S${STREAM}/Batsman?:language=en-US&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link`
      },
      {
         name: 'Bowlers Recent Form',
         url: `https://public.tableau.com/views/M3ta11-btbazrsheets-S${STREAM}/Bowlerform-Dotballs?:language=en-US&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link`
      }
   ],
   TEAM: [
      {
         name: 'Win/Loss Record Over Time',
         url: `https://public.tableau.com/views/M3ta11-btbazrsheets-S${STREAM}/WinLostRecord?:language=en-US&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link`
      },
      {
         name: 'Average Runs Scored/Conceded',
         url: `https://public.tableau.com/views/M3ta11-btbazrsheets-S${STREAM}/RunsbyTeam?:language=en-US&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link`
      },
      {
         name: 'Head-to-Head Records',
         url: `https://public.tableau.com/views/M3ta11-btbazrsheets-S${STREAM}/HeadtoHead?:language=en-US&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link`
      },
      {
         name: 'Catch and Bowled Success Rate',
         url: `https://public.tableau.com/views/M3ta11-btbazrsheets-S${STREAM}/WicketTypes?:language=en-US&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link`
      },
      {
         name: 'Team Boundry Rate',
         url: `https://public.tableau.com/views/M3ta11-btbazrsheets-S${STREAM}/BoundariesinMatch?:language=en-US&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link`
      }
   ], MATCHUP: [
      {
         name: 'Batsman vs. Each Bowler',
         url: `https://public.tableau.com/views/M3ta11-btbazrsheets-S${STREAM}/BatteragainstEachBowler?:language=en-US&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link`
      },
      {
         name: 'Bowler vs. Each Batsman',
         url: `https://public.tableau.com/views/M3ta11-btbazrsheets-S${STREAM}/BowleragainstEachBatter?:language=en-US&:sid=&:redirect=auth&:display_count=n&:origin=viz_share_link`
      }
   ]
})
