import {ApiEndpoints} from "./ApiConstants";
import AxiosInstance from "../helpers/AxiosInstance";

export const loginUser = async (data) => {
   return AxiosInstance()
      .post(ApiEndpoints.LOGIN_USER, data)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};

export const validateToken = (token) => {
   if (!token) {
      return false;
   }
   const url = `${ApiEndpoints.TOKEN_VALIDATE}`
   return AxiosInstance()
      .put(url, "", {
         headers: {
            "AuthToken": token
         }
      })
      .then((response) => {
         return response;
      })
      .catch((error) => {
         return error.response;
      });
};

export const refreshToken = (userName, token) => {
   const url = `${ApiEndpoints.REFRESH_SESSION}/${userName}`
   return AxiosInstance()
      .post(url, "", {
         headers: {
            "AuthToken": token
         }
      })
      .then((response) => {
         return response;
      })
      .catch((error) => {
         return error.response;
      });
};

export const forgotPasswordReset = async (data) => {
   try {
      const response = await AxiosInstance().put(ApiEndpoints.SEND_RESET_EMAIL, data);
      return response.data;
   } catch (error) {
      return error.response;
   }
};

export const resetPassword = async (data) => {
   try {
      const response = await AxiosInstance().post(ApiEndpoints.RESET_PASSWORD, data);
      return response.data;
   } catch (error) {
      return error.response;
   }
};

export const resetPasswordCodeValidation = async (code) => {
   return AxiosInstance()
      .get(`${ApiEndpoints.RESET_PASSWORD_CODE}?code=${code}`)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });

};
