import React, {useEffect, useState} from "react";
import styles from "./Matches.module.scss";
import NavbarSecondary from "../../components/NavigationBar/NavigationBar";
import classNames from "classnames";
import {FaSearch} from "react-icons/fa";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Paginator from "../../components/Paginator/Paginator";
import {MATCH_CARD_TYPES, SOCKET_MESSAGES} from "../../utilities/Constants";
import * as StatisticsService from "../../services/StatisticsService";
import {Link, useLocation} from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import {formatDate, formatTime} from "../../helpers/CommonFunctions";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import PubNubMatchesHandler from "./PubNubMatchesHandler";
import * as PubNubService from "../../services/PubNubService";
import {useTranslation} from "react-i18next";
import {pubNubConfigConstants} from "../../utilities/PubNubConfigConstants";

const Matches = (props) => {

   const location = useLocation();
   let uuid = localStorage.getItem("uuid");
   const {t} = useTranslation();
   const [pageLang] = useState(localStorage.getItem('language'));

   const [loadingInProgress, setLoading] = useState(true);
   const [currentPage, setCurrentPage] = useState(parseInt(new URLSearchParams(location.state).get('currentPage')) || 0);
   const [postsPerPage, setPostsPerPage] = useState(9);
   const [totalElements, setTotalElements] = useState(-1);
   const [liveMs, setliveMs] = useState([]);
   const [completedMs, setcompletedMs] = useState([]);
   const [scheduledMs, setScheduledMs] = useState([]);
   const [connectPubnub, setConnectPubnub] = useState(false);
   const [token, setToken] = useState(null);
   const [selectedRadioBtnValue, setSelectedRadioBtnValue] = useState(new URLSearchParams(location.state).get('type') || 'all');
   const [searchQuery, setSearchQuery] = useState('');
   const [subscribeChannel, setSubscribeChannel] = useState(null);
   const [unSubscribeChannel, setUnsubscribeChannel] = useState(null);
   const [translationsLoaded, setTranslationsLoaded] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 1000);
   }, []);

   useEffect(() => {

      const fetchData = async () => {
         if (localStorage.getItem("pubnubToken") === null) {
            try {
               await PubNubService.requestAccessTokenMatches(uuid, props.Token).then(response => {
                  if (response !== null) {
                     setToken(response);
                     setConnectPubnub(true);
                     localStorage.setItem("pubnubToken", response);
                  }
               })
               // pubnub.setAuthKey(response); // Update PubNub authKey
            } catch (error) {
               console.error("Error:", error);
            }
         } else {
            setConnectPubnub(true);
            setToken(localStorage.getItem("pubnubToken"));
         }
      };
      fetchData();
   }, [props.Token, uuid]);

   useEffect(() => {
      if (selectedRadioBtnValue === MATCH_CARD_TYPES.ALL && currentPage === 0) {
         setSubscribeChannel(pubNubConfigConstants.MATCHES + '-' + props.tenantId);
         setUnsubscribeChannel("Live");
      } else if (selectedRadioBtnValue === MATCH_CARD_TYPES.LIVE && currentPage === 0) {
         setSubscribeChannel("Live");
         setUnsubscribeChannel(pubNubConfigConstants.MATCHES + '-' + props.tenantId);
      } else {
         setSubscribeChannel("null");
         setUnsubscribeChannel(pubNubConfigConstants.MATCHES + '-' + props.tenantId);
         setUnsubscribeChannel("Live");
      }

      const fetchData = async () => {
         setLoading(true);
         await StatisticsService.getAllMatchCards(searchQuery, currentPage, postsPerPage, "isLive,isScheduled,matchId,desc", selectedRadioBtnValue, pageLang, props.Token, props.tenantId)
            .then((response) => {
               if (response.apiStatus && response.totalElements !== 0) {
                  setTotalElements(response.totalElements);
                  filterMatches(response);
                  window.scrollTo({top: 0, behavior: 'smooth'});
                  setLoading(false);
               } else if (response.totalElements === 0) {
                  setTotalElements(0);
                  setLoading(false);
               }
            });
      };
      fetchData();
   }, [selectedRadioBtnValue, searchQuery, currentPage, postsPerPage, pageLang, props.Token]);

   const paginate = (pageNumber, postsPerPage) => {
      setPostsPerPage(Number(postsPerPage));
      setCurrentPage(pageNumber);
   };

   const filterMatches = (response) => {
      if (response.matches) {
         setliveMs(response?.matches?.filter((match) => match.live === true && match.toStart === false));
         setScheduledMs(response?.matches?.filter((match) => match.live === false && match.toStart === true));
         setcompletedMs(response?.matches?.filter((match) => match.live === false && match.toStart === false));
      }
   };

   const handleRadioBtnValue = (event) => {
      setCurrentPage(0)
      setSelectedRadioBtnValue(event.target.value);
   };

   const handleSearchChange = (event) => {
      setCurrentPage(0)
      setSearchQuery(event.target.value)
   };

   function getHighlightStylesCompleted(matchObj, isCompletedMatch) {
      if (matchObj?.winnerId === matchObj?.team1Id) {
         return "scoreLbl";
      } else if (matchObj?.winnerId !== matchObj?.team1Id) {
         return isCompletedMatch ? "scoreLbl emptyCompleted" : "scoreLbl empty";
      } else if (!matchObj?.winnerId) {
         return isCompletedMatch ? "scoreLbl emptyCompleted" : "scoreLbl empty";
      }
   }

   function getHighlightStylesCompleted2(matchObj, isCompletedMatch) {
      if (matchObj?.winnerId === matchObj?.team2Id) {
         return "scoreLbl";
      } else if (matchObj?.winnerId !== matchObj?.team2Id) {
         return isCompletedMatch ? "scoreLbl emptyCompleted" : "scoreLbl empty";
      } else if (!matchObj?.winnerId) {
         return isCompletedMatch ? "scoreLbl emptyCompleted" : "scoreLbl empty";
      }
   }

   function getHighlightStylesLive(matchObj) {
      return matchObj?.battingTeamId === matchObj?.team1Id ? "scoreLbl" : "scoreLbl empty";
   }

   function getHighlightStylesLive2(matchObj) {
      return matchObj?.battingTeamId === matchObj?.team2Id ? "scoreLbl" : "scoreLbl empty";
   }

   const overCount = (teamOvers, teamBalls) => {

      const overs = teamBalls !== 6 ? teamOvers - 1 : teamOvers

      const balls = teamBalls !== 6 ? teamBalls : 0
      return overs + "." + balls;

   }

   const liveMatchCard = (matchObj) => (
      <div className={classNames(styles.matchCard, "col-12 col-sm-6 col-md-6 col-lg-4 ps-sm-2 pe-sm-2 ps-lg-0 pe-lg-4")}
           key={matchObj.matchId}>
         <Link to={{
            pathname: `/match/${matchObj.matchId}`,
            search: `returnUrl=${encodeURIComponent(location.pathname)}&currentPage=${currentPage}&type=${selectedRadioBtnValue}`
         }} className="matchCardInnerWrapper pt-1 pb-2" style={{textDecoration: "none"}}>
            <div className={classNames(styles.matchInfoHeaderContainer, "matchInfoHeader")}>
               <div className={classNames(styles.row1)}>
                  <div className={classNames(styles.PlayNowLiveTagContainer)}>
                     <div className={classNames(styles.PlayNowLiveTagWrapper)}>
                     </div>
                     <span className={classNames(styles.PlayNowLiveTag, "matchStatslbl-")}>
                            <span className={classNames(styles.BlinkingDot, "livedot blinking")}></span>
                           <div className={classNames(styles.LiveTag)}>PLAY NOW</div>
                        </span>
                  </div>
               </div>
               <div className={classNames(styles.row2)}>
                        <span className="matchDate">
                            <label
                               className="mtDate">{matchObj?.matchStartTime ? formatDate(matchObj?.matchStartTime) : '--'}</label>
                            <label className="mtTime">
                                {matchObj?.matchStartTime ? t("MATCHES.STARTED_AT") + formatTime(matchObj?.matchStartTime) : '--'}</label>
                        </span>
               </div>
            </div>
            <div className="teamDetail">
                    <span className="teamLogo">
                        <Link to={{
                           pathname: `/team/${matchObj?.team1Id}`,
                           search: `returnUrl=${encodeURIComponent(location.pathname)}`
                        }}>
                            <img src={matchObj?.team1PicURL} alt=""/>
                        </Link>
                    </span>
               <span className="teamName">{matchObj?.team1Name ? matchObj?.team1Name : "TBA"}</span>
               <span className={classNames(styles.teamScoreWrapper, "teamScore")}>{matchObj?.team1Runs !== null ? (
                  <label
                     className={getHighlightStylesLive(matchObj)}>{matchObj?.team1Runs}/{matchObj?.team1Wickets}</label>) : (
                  <label className={getHighlightStylesLive(matchObj)}>0/0</label>)}
                  {matchObj?.team1Overs || matchObj?.team1Balls ? (<label
                     className="oversLbl">({overCount(matchObj?.team1Overs, matchObj?.team1Balls)})</label>) : (
                     <label className="oversLbl empty">(0.0)</label>)}
                    </span>
            </div>
            <div className="teamDetail">
                    <span className="teamLogo">
                        <Link to={{
                           pathname: `/team/${matchObj.team2Id}`,
                           search: `returnUrl=${encodeURIComponent(location.pathname)}`
                        }}>
                            <img src={matchObj?.team2PicURL} alt=""/>
                        </Link>
                    </span>
               <span className="teamName">{matchObj?.team2Name ? matchObj?.team2Name : "TBA"}</span>
               <span className={classNames(styles.teamScoreWrapper, "teamScore")}>{matchObj?.team2Runs !== null ? (
                  <label
                     className={getHighlightStylesLive2(matchObj)}>{matchObj?.team2Runs}/{matchObj?.team2Wickets}</label>) : (
                  <label className={getHighlightStylesLive2(matchObj)}>0/0</label>)}
                  {matchObj?.team2Overs || matchObj?.team2Balls ? (
                     <label className="oversLbl">({overCount(matchObj?.team2Overs, matchObj?.team2Balls)})</label>) : (
                     <label className="oversLbl empty">(0.0)</label>)}
                    </span>
            </div>
         </Link>
      </div>);
   const scheduleMatchCard = (matchObj) => (
      <div className={classNames(styles.matchCard, "col-12 col-sm-6 col-md-6 col-lg-4 ps-sm-2 pe-sm-2 ps-lg-0 pe-lg-4")}
           key={matchObj.matchId}>
         <Link to={{
            pathname: `/match/${matchObj.matchId}`,
            search: `returnUrl=${encodeURIComponent(location.pathname)}&currentPage=${currentPage}&type=${selectedRadioBtnValue}`
         }} className="matchCardInnerWrapper pt-1 pb-2" style={{textDecoration: "none"}}>
            <div className={classNames(styles.matchInfoHeaderContainer, "matchInfoHeader")}>
               <div className={classNames(styles.row1)}>
                        <span className="matchStatslbl upcomeGreen">
                            <a href={`/match/${matchObj.matchId}`}
                               style={{textDecoration: "none"}}>{t("MATCHES.UPCOMING")}</a>
                        </span>
               </div>
               <div className={classNames(styles.row2)}>
                        <span className="matchDate">
                            <label
                               className="mtDate">{matchObj?.matchStartTime ? formatDate(matchObj?.matchStartTime) : ''}</label>
                            <label
                               className="mtTime">{matchObj?.matchStartTime ? t("MATCHES.STARTS_AT") + formatTime(matchObj?.matchStartTime) : formatDate(matchObj?.matchStartTime)}</label>
                        </span>
               </div>
            </div>
            <div className="teamDetail">
                    <span className="teamLogo">
                        <Link to={{
                           pathname: `/team/${matchObj.team1Id}`,
                           search: `returnUrl=${encodeURIComponent(location.pathname)}`
                        }}>
                            <img src={matchObj?.team1PicURL} alt=""/>
                        </Link>
                    </span>
               <span className="teamName">{matchObj?.team1Name ? matchObj?.team1Name : "TBA"}</span>
               <span className={classNames(styles.teamScoreWrapper, "teamScore")}>
                        {/* Removed Runs From Scheduled Matches */}
                  <label className="scoreLbl empty">0/0</label>
                        <label className="oversLbl empty">(0.0)</label>
                    </span>
            </div>
            <div className="teamDetail">
                    <span className="teamLogo">
                        <Link to={{
                           pathname: `/team/${matchObj.team2Id}`,
                           search: `returnUrl=${encodeURIComponent(location.pathname)}`
                        }}>
                            <img src={matchObj?.team2PicURL} alt=""/>
                        </Link>
                    </span>
               <span className="teamName">{matchObj?.team2Name ? matchObj?.team2Name : "TBA"}</span>
               <span className={classNames(styles.teamScoreWrapper, "teamScore")}>
                        <label className="scoreLbl empty">0/0</label>
                        <label className="oversLbl empty">(0.0)</label>
                    </span>
            </div>
         </Link>
      </div>);
   const completedMatchCard = (matchObj) => (
      <div className={classNames(styles.matchCard, "col-12 col-sm-6 col-md-6 col-lg-4 ps-sm-2 pe-sm-2 ps-lg-0 pe-lg-4")}
           key={matchObj.matchId}>
         <Link to={{
            pathname: `/match/${matchObj.matchId}`,
            search: `returnUrl=${encodeURIComponent(location.pathname)}&currentPage=${currentPage}&type=${selectedRadioBtnValue}`
         }} className="matchCardInnerWrapper pt-1 pb-2" style={{textDecoration: "none"}}>
            <div className={classNames(styles.matchInfoHeaderContainer, "matchInfoHeader")}>
               <div className={classNames(styles.row1)}>
                        <span className="matchStatslbl completedBlue" style={{textDecoration: "none"}}>
                            <a href={`/match/${matchObj.matchId}`}
                               style={{textDecoration: "none"}}>{t("MATCHES.COMPLETED")}</a>
                        </span>
               </div>
               <div className={classNames(styles.row2)}>
                        <span className={classNames(styles.matchDateWrapper, "matchDate")}>
                            <label
                               className="mtDate">{matchObj?.message ? matchObj?.message : formatDate(matchObj?.matchStartTime)}</label>
                            <label className="mtDate">
                                {matchObj?.matchEndTime ? formatDate(matchObj?.matchEndTime, true) + ", " : ''}
                               {matchObj?.matchEndTime ? formatTime(matchObj?.matchEndTime) : formatDate(matchObj?.matchEndTime)}
                            </label>
                        </span>
               </div>
            </div>
            <div className="teamDetail">
                    <span className="teamLogo">
                        <Link to={{
                           pathname: `/team/${matchObj.team1Id}`,
                           search: `returnUrl=${encodeURIComponent(location.pathname)}`
                        }}>
                            <img src={matchObj?.team1PicURL} alt=""/>
                        </Link>
                    </span>
               <span className="teamName">{matchObj?.team1Name ? matchObj?.team1Name : "TBA"}</span>
               <span className={classNames(styles.teamScoreWrapper, "teamScore")}>

                        {matchObj?.team1Runs !== null ? (<label
                           className={getHighlightStylesCompleted(matchObj, true)}>{matchObj?.team1Runs}/{matchObj?.team1Wickets}</label>) : (
                           <label className={getHighlightStylesCompleted(matchObj, true)}>0/0</label>)}
                  {matchObj?.team1Overs || matchObj?.team1Balls ? (
                     <label className="oversLbl">({overCount(matchObj?.team1Overs, matchObj?.team1Balls)})</label>) : (
                     <label className="oversLbl empty">(0.0)</label>)}
                    </span>
            </div>
            <div className="teamDetail">
                    <span className="teamLogo">
                        <Link to={{
                           pathname: `/team/${matchObj.team2Id}`,
                           search: `returnUrl=${encodeURIComponent(location.pathname)}`
                        }}>
                            <img src={matchObj?.team2PicURL} alt=""/>
                        </Link>
                    </span>
               <span className="teamName">{matchObj?.team2Name ? matchObj?.team2Name : "TBA"}</span>
               <span className={classNames(styles.teamScoreWrapper, "teamScore")}>{matchObj?.team2Runs !== null ? (
                  <label
                     className={getHighlightStylesCompleted2(matchObj, true)}>{matchObj?.team2Runs}/{matchObj?.team2Wickets}</label>) : (
                  <label className={getHighlightStylesCompleted2(matchObj, true)}>0/0</label>)}
                  {matchObj?.team2Overs || matchObj?.team2Balls ? (
                     <label className="oversLbl">({overCount(matchObj?.team2Overs, matchObj?.team2Balls)})</label>) : (
                     <label className="oversLbl empty">(0.0)</label>)}
                    </span>
            </div>
         </Link>
      </div>);

   const loadingIndicator = (<div className="w-100 text-center">
      <div className={styles.loaderContainer}>
         <div className="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
         </div>
      </div>
   </div>);

   const renderAllMatches = () => {
      if (loadingInProgress) {
         return loadingIndicator;
      }
      return totalElements === 0 ? (<div className={styles.loaderContainer}>
         <p className="gray-text font18">
            {t("MATCHES.NO_MATCHES_FOUND")}
         </p>
      </div>) : (<div className={classNames(styles.matchCardWrapper, "matchCards ps-0 ps-sm-0 ps-xl-0")}>
         {liveMs?.map((matchObj) => liveMatchCard(matchObj))}
         {scheduledMs?.map((matchObj) => scheduleMatchCard(matchObj))}
         {completedMs?.map((matchObj) => completedMatchCard(matchObj))}
      </div>)
   }
   const messageFromMatchesCallback = (message) => {
      console.log(JSON.parse(message))
      try {
         let json = JSON.parse(message);

         if (currentPage === 0 && searchQuery === "") {

            if (selectedRadioBtnValue === MATCH_CARD_TYPES.ALL || selectedRadioBtnValue === MATCH_CARD_TYPES.LIVE) {

               if (json.bodyType === SOCKET_MESSAGES.MATCH_CARD_DETAILS) {
                  filterMatches(json.body);
               } else {
                  setLoading(false);
               }
            }
         } else if (currentPage !== 0) {
            if (selectedRadioBtnValue !== MATCH_CARD_TYPES.ALL || selectedRadioBtnValue !== MATCH_CARD_TYPES.LIVE) {
               setUnsubscribeChannel("Matches");
               setUnsubscribeChannel("Live")
            }
         }

      } catch (error) {
         console.error("JSON parsing error:", error);
      }
   }
   return (<React.Fragment>
      {(connectPubnub && (token !== null)) ?
         <PubNubMatchesHandler onRecieved={messageFromMatchesCallback} token={token} subscribe={subscribeChannel}
                               onUnsubscribe={unSubscribeChannel}/> : <></>}

      <div className="Radial1"></div>
      <div className={classNames(styles.lightBolt)}></div>
      <div className={classNames(styles.ellipse50)}></div>

      <div className="pb-4">
         <div className="col-12 row">
            <div
               className={classNames(styles.ResponceCont, "col-12", "row", "ps-2", "pe-2", "ps-xxl-0", "pe-xxl-0")}>
               <div className="col-12  p-0">
                  <div className={classNames(styles.matchesProfileGuidanceTip, "matchesProfileGuidanceTip")}
                       style={{height: '1px', width: '100%', display: 'block'}}>
                     {/*<GuidanceTip page="matches" gotItIndex={1} gotItHandler={gotItHandler} nextGotItNum={nextGotItIndex} tipCancelHandler={tipCancelHandler} tipIndexCounter={tipIndexCounter} setTipIndexCounter={setTipIndexCounter}/>*/}
                  </div>
                  <NavbarSecondary page="matches"/>
               </div>
               {!translationsLoaded ? <> </> : <>
                  <div className="row gx-0 mt99">
                     <div
                        className="mb-3 mb-md-0 p-0 col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 col-xxl-4">
                        <h1 className={classNames("mainHeaderH1", "mt-0")}>
                           {t("MATCHES.MATCHES")}
                        </h1>

                     </div>

                     <div
                        className={classNames(styles.MatchTypeWrapperContainer, "MatchTypeWrapperContainer p-0 col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8 selectWrapper")}
                        style={{display: "flex"}}>
                        <div
                           className={classNames(styles.matchTypeWrapper, "fc-wrapper matches-radio-wrapper row mb-4 mb-sm-0")}>

                           <label
                              className="radioButtonlbl order-sm-2 order-2 m-0 pe-2 col-md-auto col-6 p-0 mb-2 mb-sm-0">
                              <input type="radio" name="radio" value='all'
                                     checked={selectedRadioBtnValue === MATCH_CARD_TYPES.ALL}
                                     onChange={handleRadioBtnValue}/>
                              <span>{t("MATCHES.ALL")}</span>
                           </label>
                           <label
                              className="radioButtonlbl order-sm-2 order-2 m-0 pe-0 pe-sm-2 col-md-auto col-6 p-0 mb-2 mb-sm-0">
                              <input type="radio" name="radio" value='live'
                                     checked={selectedRadioBtnValue === MATCH_CARD_TYPES.LIVE}
                                     onChange={handleRadioBtnValue}/>
                              <span>{t("MATCHES.LIVE_2")}</span>
                           </label>
                           <label
                              className="radioButtonlbl order-sm-2 order-2 m-0 pe-2 col-md-auto col-6 p-0 mb-2 mb-sm-0">
                              <input type="radio" name="radio" value='upcoming'
                                     checked={selectedRadioBtnValue === MATCH_CARD_TYPES.UPCOMING}
                                     onChange={handleRadioBtnValue}/>
                              <span>{t("MATCHES.UPCOMING_2")}</span>
                           </label>
                           <label
                              className="radioButtonlbl order-sm-2 order-2 m-0 col-md-auto col-6 p-0 mb-2 mb-sm-0">
                              <input type="radio" name="radio" value='completed'
                                     checked={selectedRadioBtnValue === MATCH_CARD_TYPES.COMPLETED}
                                     onChange={handleRadioBtnValue}/>
                              <span>{t("MATCHES.COMPLETED")}</span>
                           </label>
                           <div
                              className={classNames(styles.matchesMatchesGuidanceTip, "matchesMatchesGuidanceTip")}>
                           </div>
                           <InputGroup
                              className={classNames(styles.searchField, "searchFieldWrapper searchtxt order-sm-1 order-1 me-2 col-md-auto col-12 p-0")}>
                              <div className={classNames(styles.matchesSearchGuidanceTip)}
                                   style={{height: '1px', width: '10px', display: 'block'}}>
                              </div>
                              <Form.Control style={{height: '54px'}} placeholder={t("MATCHES.SEARCH")}
                                            aria-label="SearchForArtworks" aria-describedby="basic-addon1"
                                            value={searchQuery}
                                            onChange={handleSearchChange}/>
                              <InputGroup.Text style={{height: '54px'}} id="basic-addon1">
                                 <FaSearch/>
                              </InputGroup.Text>
                           </InputGroup>
                        </div>
                     </div>
                  </div>

                  <div className={classNames(styles.matchCardContainer, "col-12 p-0")}>
                     <div className={classNames(styles.contentWrapper, "row gx-0 mt40")}>
                        <div className="col-xl-9 col-lg-12 p-0 float-left">
                           {renderAllMatches()}
                           {totalElements > 0 && !loadingInProgress ?
                              <div className="pageWrap" style={{marginTop: "45px"}}>
                                 <Paginator postsPerPage={postsPerPage} totalElements={totalElements}
                                            currentPage={currentPage} paginate={paginate}/>
                              </div> : <></>}
                        </div>
                     </div>
                  </div>
               </>}

            </div>
         </div>
         {!translationsLoaded ? <></> : <>
            <div className="mt-2">
               <Footer/>
            </div>
         </>}
      </div>
   </React.Fragment>);
};

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null || undefined,
      userId: state.auth.userId,
      userName: state.auth.userName,
      email: state.auth.email,
      Token: state.auth.accessToken,
      refresh: state.auth.refreshToken,
      tenantId: state.auth.tenantId
   };
};
const mapDispatchToProps = (dispatch) => {
   return {
      onLogout: () => dispatch(actions.logout()),
      onRefreshAuth: (accessToken, refreshToken) => dispatch(actions.refreshSession(accessToken, refreshToken)),
   };
};
export default connect(mapStateToProps, mapDispatchToProps)(Matches);
