import React, {useEffect, useState} from "react";
import PubNub from "pubnub";
import {PUBNUB_KEYS} from "../../utilities/Constants";
import {connect} from "react-redux";
import {pubNubConfigConstants} from "../../utilities/PubNubConfigConstants";

const MatchDataHandler = (props) => {

   const [channels, setChannels] = useState([`${pubNubConfigConstants.MATCH_DATA}-${props.matchId}`]);
   const [pubnub, setPubNub] = useState(null);

   useEffect(() => {
      // Initialize PubNub if the user is authenticated
      if (props.isAuthenticated) {
         const pubnubInstance = new PubNub({
            publishKey: PUBNUB_KEYS.PUBLISH_KEY,
            subscribeKey: PUBNUB_KEYS.SUBSCRIBE_KEY,
            uuid: props.userName || 'default-uuid',
            authKey: props.token,
         });
         setPubNub(pubnubInstance);
         return () => {
            pubnubInstance.unsubscribeAll();
            pubnubInstance.stop();
         };
      }
   }, [props.isAuthenticated, props.token, props.userName]);

   useEffect(() => {
      // Subscribe to channels when PubNub instance is available and channels are updated
      if (pubnub) {
         pubnub.addListener({message: handleMessage});
         pubnub.subscribe({channels});
      }
      // Unsubscribe from channels when component unmounts
      return () => {
         if (pubnub) {
            pubnub.removeListener({message: handleMessage});
            pubnub.unsubscribe({channels});
         }
      };
   }, [channels, pubnub]);

   useEffect(() => {
      if (props.sendMessage != null) {
         pubNubMessageSender(props.sendMessage.message, props.sendMessage.channel)
      } else {
         console.log("message is null")
      }
   }, [props.sendMessage]);

   useEffect(() => {
      if (props.subscribe != null) {
         pubNubSubscriber(props.subscribe)
      }
   }, [props.subscribe]);

   useEffect(() => {
      if (props.unSubscribe != null) {
         pubNubUnSubscriber(props.unSubscribe)
      }
   }, [props.unSubscribe]);

   const handleMessage = (event) => {
      const {message} = event;
      // Handle the received message as needed
      if (event.channel == `MatchData-${props.matchId}`) {
         props.onDataReceived(message)
      }
   };

   // PubNub message sender
   const pubNubMessageSender = (message, channel) => {
      if (pubnub) {
         let jsonString = JSON.stringify(message);
         pubnub.publish({message: JSON.parse(jsonString), channel});
      }
   };

   // PubNub subscriber
   const pubNubSubscriber = (channel) => {
      if (pubnub) {
         setChannels((prevChannels) => [...prevChannels, channel]);
         pubnub.subscribe({channels: [channel]});
      }
   };

   // PubNub unsubscriber
   const pubNubUnSubscriber = (channel) => {
      if (pubnub) {
         setChannels((prevChannels) => prevChannels.filter((c) => c !== channel));
         pubnub.unsubscribe({channels: [channel]});
      }
   };
   return (<></>)
}

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
      refresh: state.auth.refreshToken,
      Token: state.auth.accessToken,
      userId: state.auth.userId,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      email: state.auth.email
   };
}

export default connect(mapStateToProps)(MatchDataHandler);
