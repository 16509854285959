import {ApiEndpoints} from "./ApiConstants";
import AxiosInstance from "../helpers/AxiosInstance";

export const getAllMatches = (lang, authToken, page, size, sort, tenantId) => {
   const url = `${ApiEndpoints.MATCH_STAT_CARDS}?lang=${lang}&page=${page}&size=${size}&sort=${sort}&tenant=${tenantId}`
   return AxiosInstance()
      .get(url, {
         headers: {
            "AuthToken": authToken
         }
      })
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};

export const getMatchStat = (matchId, authToken) => {
   const url = `${ApiEndpoints.LIVE_MATCH_STAT}/${matchId}`
   return AxiosInstance()
      .get(url, {
         headers: {
            "AuthToken": authToken
         }
      })
      .then((response) => {
         return {
            data: response.data, status: response.status
         };
      })
      .catch((error) => {
         return error.response;
      });
};

export const getLiveMatchesStatus = () => {
   const url = ApiEndpoints.LIVE_MATCHES_STATUS;

   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};

export const getOverSummary = (matchId) => {
   const url = `${ApiEndpoints.GET_OVER_SUMMARY}/${matchId}`;
   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
}

export const getAllMatchCards = (searchQuery, currentPage, postsPerPage, sort, selectedRadioBtnValue, pageLang, authToken, tenantId) => {
   let url;
   if (searchQuery) {
      url = `${ApiEndpoints.MATCH_STAT_CARDS}?attribute=${searchQuery}&page=${currentPage}&size=${postsPerPage}&sort=${sort}&type=${selectedRadioBtnValue}&lang=${pageLang}&tenant=${tenantId}`;
   } else {
      url = `${ApiEndpoints.MATCH_STAT_CARDS}?page=${currentPage}&size=${postsPerPage}&sort=${sort}&type=${selectedRadioBtnValue}&lang=${pageLang}&tenant=${tenantId}`;
   }
   return AxiosInstance()
      .get(url, {
         headers: {
            "AuthToken": authToken
         }
      })
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};
