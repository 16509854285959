import React, {useEffect, useState} from "react";
import PubNub from "pubnub";
import {PUBNUB_KEYS} from "../../utilities/Constants";
import {connect} from "react-redux";
import {pubNubConfigConstants} from "../../utilities/PubNubConfigConstants";


const PubNubMatchesHandler = (props) => {

   //props tenatid
   const [channels, setChannels] = useState([pubNubConfigConstants.MATCHES + '-' + props.tenantId]);
   // rewards_{tournamentId}

   let pubnub;
   // Create the PubNub instance outside the useEffect
   pubnub = new PubNub({
      publishKey: PUBNUB_KEYS.PUBLISH_KEY,
      subscribeKey: PUBNUB_KEYS.SUBSCRIBE_KEY,
      uuid: localStorage.getItem("uuid"),
      authKey: props.token, // Initialize with token, which may be null initially
   });

   useEffect(() => {

      const listenerParams = {message: handleMessage};

      pubnub.addListener(listenerParams);
      pubnub.subscribe({channels});
      console.log("subscribed " + channels);
      // Call the async function

      // console.log(pubnub.parseToken(token))
      return () => {
         console.log("unsub " + channels);
         pubnub.unsubscribe({channels});
         pubnub.removeListener(listenerParams);
      };
   }, [channels, props.isAuthenticated, props.userName]);

   //TODO userName inject


   const handleMessage = event => {
      // console.log(event)
      let message = event.message;
      // console.log(message);
      props.onRecieved(message)


      // if (message.body.live == true && message.body.matchId == matchId){
      //
      // }else{
      //   console.log(message.body.matchId)
      // }

   }
   const pubNubMessageSender = (message, channel) => {
      console.log("send", message)
      let jsonString = JSON.stringify(message);
      pubnub
         .publish({
            message: JSON.parse(jsonString),
            channel: channel,
         });
   }
   const pubNubSubscriber = (channel) => {
      console.log("Subscribed: ", channel)
      pubnub.subscribe(channel)
      setChannels([channel])
   }
   const pubNubUnSubscriber = (channel) => {
      pubnub.unsubscribe(channel)
   }
   useEffect(() => {
      if (props.sendMessage != null) {
         pubNubMessageSender(props.sendMessage.message, props.sendMessage.channel)
      } else {
         console.log("message is null")
      }

   }, [props.sendMessage]);

   useEffect(() => {
      if (props.subscribe != null) {
         pubNubSubscriber(props.subscribe)
      }
   }, [props.subscribe]);

   useEffect(() => {
      if (props.unSubscribe != null) {
         pubNubUnSubscriber(props.unSubscribe)
      }
   }, [props.unSubscribe]);


// useEffect(()=>{
//   if (props.isAuthenticated){
//     setChannels(prevState => [...prevState,props.userName])
//   }
// },[])
   return (<></>)
}
const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
      refresh: state.auth.refreshToken,
      Token: state.auth.accessToken,
      userId: state.auth.userId,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      email: state.auth.email,
      tenantId: state.auth.tenantId
   };
}

export default connect(mapStateToProps)(PubNubMatchesHandler);
