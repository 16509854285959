export const MATCH_CARD_TYPES = Object.freeze({
   ALL: 'all',
   LIVE: 'live',
   UPCOMING: 'upcoming',
   COMPLETED: 'completed',
   PURCHASED: 'user_purchased',
})

export const ENCRYPTION_KEY = Object.freeze({
   SECRET: "meta11qatestsecretkey"
})

export const PUBNUB_KEYS = Object.freeze({
   // eslint-disable-next-line no-undef
   PUBLISH_KEY: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
   // eslint-disable-next-line no-undef
   SUBSCRIBE_KEY: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY
})

export const SITE_LANGUAGE = Object.freeze({
   ENGLISH: 'en',
   HINDI: 'hi'
})

export const SOCKET_MESSAGES = Object.freeze({
   MATCH_STATUS: 'MATCH_STATUS',
   AUTH_RESPONSE: 'AUTH_RESPONSE',
   SUBSCRIPTION_RESPONSE: 'SUBSCRIPTION_RESPONSE',
   MATCH_CARD_STATUS: 'MATCH_CARD_STATUS',
   MATCH_CARD_DETAILS: 'MATCH_CARD_DETAILS',
})
