import {ApiEndpoints} from "./ApiConstants";
import AxiosInstance from "../helpers/AxiosInstance";

export const getTeamProfileDetailsById = (teamId, lang) => {
   const url = `${ApiEndpoints.GET_TEAM_BY_TEAMID}/${teamId}?lang=${lang}`
   return AxiosInstance()
      .get(url)
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         return error.response;
      });
};
