import React from "react";
import Styles from "./MatchPauseConfirmationModal.module.scss"
import Classnames from "classnames"
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {putMatchPause} from "../../services/MatchConfigService";
import {toast} from "react-toastify";

const MatchPauseConfirmationModal = ({onCloseModal, matchId, isPaused, setIsPaused}) => {
   const closeModal = () => {
      onCloseModal(false);
   }

   const handlePauseMatch = async () => {
      await putMatchPause(matchId, !isPaused)
         .then(response => {
            if (response?.apiStatus) {
               toast.success(`Successfully ${isPaused ? 'Resumed' : 'Paused'}`);
               setIsPaused(!isPaused);
               onCloseModal();
            } else {
               toast.error('Error updating the state');
            }
         })

   }

   return (
      <div className={Classnames("modal fade show", Styles.modalBlur)} id="exampleModalCenter" tabIndex="-1"
           role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{display: "block"}}>
         <div className={Classnames("modal-dialog-centered", Styles.ModalDialog)} role="document">
            <div className={Classnames(Styles.ModalContent, "modal-content")}>
               {/*Modal-1 Started*/}
               <div className={Classnames(Styles.ModalHeader, "modal-header")}>
                  <h5 className="modal-title" id="exampleModalLabel">{/*Modal title*/}</h5>
                  <button onClick={closeModal} type="button"
                          className={Classnames(Styles.CloseIcon, "close")} data-dismiss="modal"
                          aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                  </button>
               </div>
               <div className={Classnames(Styles.ModalCont)}>

                  <div className="p-2 row">
                     <div className={Classnames(Styles.ModalTitle, "text-center")} style={{fontSize: "22px"}}>
                        Are you sure you want to pause the match?
                     </div>
                     <div className={Classnames(Styles.ModalSmallText, "pt-3", "text-center")}>
                        It will pause the upcoming matches also.
                     </div>
                     <div className="mt-4 d-flex flex-row w-100 justify-content-between">
                        <button onClick={onCloseModal}
                                className="blackBtn"
                                style={{width: "45%", height: "54px", padding: "14px 27px"}}
                        >
                           No
                        </button>
                        <button type="submit"
                                onClick={handlePauseMatch}
                                className={Classnames(Styles.LoginButton)}
                                style={{width: "45%"}}
                        >
                           Yes
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

   );
};
const mapStateToProps = state => {
   return {
      isAuthenticated: state.auth.token !== null,
   }
}

const mapDispatchToProps = dispatch => {
   return {
      onAuth: (accessToken, refreshToken, oAuthProviderType, imageURL, userName, name, email) => dispatch(actions.auth(accessToken, refreshToken, oAuthProviderType, imageURL, userName, name, email))
   }
}
export default connect(mapStateToProps, mapDispatchToProps)(MatchPauseConfirmationModal);
