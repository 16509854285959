export const MATCH_PAGE = Object.freeze({
   YOUTUBE_VIDEO_ID: "YOUTUBE_VIDEO_ID",
   SHOW_META_11_MATCH_PAGE: 'SHOW_META_11_MATCH_PAGE',
   FETCH_ALL_MATCHES_CARD_TOURNAMENT: 'FETCH_ALL_MATCHES_CARD_TOURNAMENT'
})

export const LOGIN_PAGE = Object.freeze({
   SHOW_META_11_LOGIN: 'SHOW_META_11_LOGIN',
})

export const NAVBAR = Object.freeze({
   SHOW_NAVBAR_TOURNAMENTS_LINK: 'SHOW_NAVBAR_TOURNAMENTS_LINK',
})
