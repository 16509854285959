import React from "react";
import {BattingStylesEN, BowlingStylesEN} from "../../utilities/Enums";
import {TEMP_DATA} from "../../containers/Stats/TEMP_DATA";

const AllRoundersStatTable = () => {
   return (
      <div className="d-flex align-items-center justify-content-center">
         This is still under development.
      </div>
   )

   // eslint-disable-next-line no-unreachable
   return (
      <table className={'position-relative'}>
         <thead>
         <tr>
            <th>Name</th>
            <th>Batting Style</th>
            <th>Bowling Style</th>
            <th>Total Runs{' '}
               <i className="fa fa-sort" aria-hidden="true"></i>
            </th>
            <th>Strike Rate{' '}
               <i className="fa fa-sort" aria-hidden="true"></i>
            </th>
            <th>Total Wickets{' '}
               <i className="fa fa-sort" aria-hidden="true"></i>
            </th>
            <th>Bowling Average{' '}
               <i className="fa fa-sort" aria-hidden="true"></i>
            </th>
            <th>Total Matches Played{' '}
               <i className="fa fa-sort" aria-hidden="true"></i>
            </th>
         </tr>
         </thead>
         <tbody>
         {TEMP_DATA.ALL_ROUNDERS?.map((player, index) => (
            <tr key={index} style={{height: "30px"}}>
               <td>{player?.playerName}</td>
               <td>{BattingStylesEN[player?.battingStyle]}</td>
               <td>{BowlingStylesEN[player?.bowlingStyle]}</td>
               <td>{player?.playerStat?.runs}</td>
               <td>{player?.playerStat?.strikeRate.toFixed(2)}</td>
               <td>{player?.playerStat?.wickets}</td>
               <td>{player?.playerStat?.bowlingAverage.toFixed(2)}</td>
               <td>{player?.playerStat?.matches}</td>
            </tr>
         ))}

         </tbody>
      </table>
   )
}
export default AllRoundersStatTable
