import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import GameVector from "../../assets/images/GameVector.svg";
import youVector from "../../assets/images/youVector.svg";
import twitterVector from "../../assets/images/twitterVector.svg";
import fbVector from "../../assets/images/fbVector.svg";
import classNames from "classnames";
import styles from "./Footer.module.scss";
import {useTranslation} from "react-i18next";
import Dropdown from 'react-bootstrap/Dropdown';
import globe from "../../assets/images/globe-03.webp";
import Telegram from "../../assets/images/icons/Telegram_icon.svg";
import {getConf} from "../../services/ConfigService";
import {MATCH_PAGE} from "../../utilities/ConfigConstants";

const Footer = () => {
   const {t} = useTranslation();
   const {i18n} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);

   const handleLanguageChange = (selectedLanguage) => {
      const prevLang = localStorage.getItem('language');
      localStorage.setItem('language', selectedLanguage);
      i18n.changeLanguage(selectedLanguage);
      if (prevLang !== selectedLanguage) {
         window.location.reload();
      }
   };

   useEffect(() => {
      localStorage.setItem('language', i18n.language === 'hi' ? 'hi' : 'en');
   }, []);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);

   if (!translationsLoaded) {
      return "";
   }

   return (<div className={classNames(styles.footerWrapper, "row gx-0 menuFooter")}>
      <div className="col-12">
         <div className="container mt-5 mt-sm-0">
            <div className={classNames(styles.uppeFooter)}>
               <div className="row gx-0 mobile">
                  <div className="MetaLogoCol col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                     <div className={classNames(styles.metalogoWrapper, "metalogoWrapper")}>
                        {getConf(MATCH_PAGE.SHOW_META_11_MATCH_PAGE) &&
                           <Link to={"/"} onClick={() => window.scrollTo(0, 0)}>
                              <img src={"https://storage.googleapis.com/meta11/serviceplatform/META11betaLogo.png"}
                                   alt={""}/>
                           </Link>
                        }
                     </div>
                  </div>

                  {/* <div className="desktop-footer-links">
                                <a href="/terms-and-conditions" className={classNames(styles.footerLinks)}>
                                    TERMS AND CONDITIONS
                                </a>

                                <a href="/privacy-policy" className={classNames(styles.footerLinks)}>
                                    PRIVACY POLICY
                                </a>
                                <a href="mailto:support@behaviol.com" className={classNames(styles.footerLinks)}>
                                    support@behaviol.com
                                </a>
                    </div> */}

                  <div
                     className={classNames(styles.socialLinksWrapper, "d-flex col-12 col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8 mobile-lang")}
                     style={{justifyContent: "end"}}>
                     <div className={classNames(styles.LangWrapper)}>
                        <Dropdown onSelect={handleLanguageChange}>
                           <Dropdown.Toggle variant="success" id="dropdown-basic" className="langDrop">
                              <img src={globe} alt={""}/> {i18n.language === 'hi' ? 'हिंदी' : 'English'}
                           </Dropdown.Toggle>

                           <Dropdown.Menu>
                              <Dropdown.Item eventKey="en">English</Dropdown.Item>
                              <Dropdown.Item eventKey="hi">हिंदी</Dropdown.Item>
                           </Dropdown.Menu>
                        </Dropdown>
                     </div>
                     <ul className={classNames(styles.socialUi,)}>
                        <li>
                           <div className={classNames(styles.social)}>
                              <a href="https://discord.gg/EMFxXvsU37" target="_blank" rel="noreferrer">
                                 <img src={GameVector} alt={""}/>
                              </a>
                           </div>
                        </li>
                        <li>
                           <div className={classNames(styles.social)}>
                              <a href="https://www.youtube.com/@meta11official" target="_blank" rel="noreferrer">
                                 <img src={youVector} alt={""}/>
                              </a>
                           </div>
                        </li>
                        <li>
                           <div className={classNames(styles.social)}>
                              <a href="https://www.instagram.com/meta11official/" target="_blank" rel="noreferrer">
                                 <img src="https://storage.googleapis.com/meta11/serviceplatform/Insta-Icon.png"
                                      alt={""}/>
                              </a>
                           </div>
                        </li>
                        <li>
                           <div className={classNames(styles.social)}>
                              <a href="https://twitter.com/Meta11official" target="_blank" rel="noreferrer">
                                 <img src={twitterVector} alt={""}/>
                              </a>
                           </div>
                        </li>
                        <li>
                           <div className={classNames(styles.social)}>
                              <a href="https://www.facebook.com/meta11official/" target="_blank" rel="noreferrer">
                                 <img src={fbVector} alt={""}/>
                              </a>
                           </div>
                        </li>
                        <li>
                           <div className={classNames(styles.social)}>
                              <a href="https://t.me/meta11cricket" target="_blank" rel="noreferrer">
                                 <img src={Telegram} alt={""}/>
                              </a>
                           </div>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>

            <div className={classNames(styles.siteFooter, "site-footer")}>
               <div className={classNames(styles.copyRightMsg, "gray-text font16")}>
                  © {t("FOOTER.COPYRIGHT_2023_BY")} &nbsp;<a href={"https://www.behaviol.com/"}
                                                             className={"gray-text font16"}
                                                             style={{textDecoration: "none"}}>{t("FOOTER.BEHAVIOL")}</a>
               </div>
               <div className={classNames(styles.footerLinks)}>
                  <a href="/terms-and-conditions" className={classNames(styles.footerLinks)}
                     style={{textDecoration: 'none'}}>
                     {t("FOOTER.TERMS_AND_CONDITIONS")}
                  </a>
                  <a href="/privacy-policy" className={classNames(styles.footerLinks)}
                     style={{textDecoration: 'none'}}>
                     {t("FOOTER.PRIVACY_POLICY")}
                  </a>
                  <a href="mailto:support@behaviol.com" className={classNames(styles.footerLinks)}
                     style={{textDecoration: 'none'}}>
                     support@behaviol.com
                  </a>
                  <div className={classNames(styles.copyRightMsg2)}>
                     © {t("FOOTER.COPYRIGHT_2023_BY")} &nbsp;<a href={"https://www.behaviol.com/"}
                                                                className={"gray-text font16"}
                                                                style={{textDecoration: "none"}}>{t("FOOTER.BEHAVIOL")}</a>
                  </div>
               </div>
            </div>


         </div>
      </div>
      {/* <div className="bottomGradient"></div> */}
   </div>);
};

export default Footer;
