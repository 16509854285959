export const ApiEndpoints = {
   BASE_URL: "/api",

   //User APIs
   LOGIN_USER: "/admins/login",
   TEST_LOGIN_WITH_META11: "/auth/user/login",
   SEND_VERIFICATION_EMAIL: "/user/verification",
   SEND_RESET_EMAIL: "/user/forgotPassword",
   RESET_PASSWORD: "/user/resetPassword",
   RESET_PASSWORD_CODE: "/user/reset-password/code",

   //Auth APIs
   SOCIAL_LOGIN: "/auth/user/socialLogin",
   REFRESH_SESSION: "/auth/api/refresh",
   TOKEN_VALIDATE: "/auth/user/auth/token",

   //Digital Asset APIs
   GET_TEAM_BY_TEAMID: "/digitalAssets/team",

   //Statistics Service APIs -> with tenantID
   FETCH_ALL_MATCHES_BY_TOURNAMENT_ID: "/statistics/matches/tournament",
   MATCH_STAT_CARDS: "/statistics/matches/card",

   //Statistics Service APIs
   LIVE_MATCH_STAT: "/statistics/matches",
   LIVE_MATCHES_STATUS: "/statistics/matches/live",
   GET_OVER_SUMMARY: "/statistics/matches/overSummary",

   //Schedule Service APIs
   GET_SCHEDULED_MATCH: "/scheduler/matches",
   GET_PLAYING_ORDER: "/scheduler/squad/order",

   //Pubnub Service APIs
   GET_MATCH_TOKEN: "/message/token/match",
   CHECK_MATCHES_TOKEN: "/message/token/check",
   GET_MATCHES_TOKEN: "/message/token/matches",

   //Tournament Service APIs
   GET_TOURNAMENT: "/tournament",

   //Match Config Service APIs
   PUT_MATCH_PAUSE: "/matchConfig/match/pause",
};
