import classNames from "classnames";
import React, {useEffect} from "react";
import styles from "./Schedule.module.scss";
import NavbarSecondary from "../../components/NavigationBar/NavigationBar"
import Footer from "../../components/Footer/Footer";
import {connect} from "react-redux";

const Schedule = () => {

   const sendRequest = () => {
      const formData = new FormData(document.getElementById("schedulerForm"));
      const selectedDate = formData.get("date");
      const serverId = formData.get("server");
      const selectedTeams = [];


      // Collect selected teams in order
      for (let i = 1; i <= 8; i++) {
         const team = parseInt(formData.get(`team${i}`));
         if (!team) {
            alert(`Please select all 8 teams.`);
            return;
         }
         selectedTeams.push(team);
      }

      // Validate unique teams
      // const uniqueTeams = new Set(selectedTeams);
      // if (uniqueTeams.size !== selectedTeams.length) {
      //   alert("Each team must be unique. Please select different teams.");
      //   return;
      // }

      // Matches configuration
      const matches = [
         {team1: 0, team2: 1, time: "05:00:00.000Z"},
         {team1: 2, team2: 3, time: "05:45:00.000Z"},
         {team1: 2, team2: 1, time: "06:30:00.000Z"},
         {team1: 3, team2: 0, time: "07:15:00.000Z"},
         {team1: 0, team2: 2, time: "08:10:00.000Z"},
         {team1: 3, team2: 1, time: "08:55:00.000Z"},
         {team1: 1, team2: 0, time: "09:40:00.000Z"},
         {team1: 3, team2: 2, time: "10:25:00.000Z"},
         {team1: 1, team2: 2, time: "11:10:00.000Z"},
         {team1: 0, team2: 3, time: "11:55:00.000Z"},
         {team1: 4, team2: 5, time: "13:30:00.000Z"},
         {team1: 6, team2: 7, time: "14:15:00.000Z"},
         {team1: 6, team2: 5, time: "15:00:00.000Z"},
         {team1: 7, team2: 4, time: "15:45:00.000Z"},
         {team1: 4, team2: 6, time: "16:45:00.000Z"},
         {team1: 7, team2: 5, time: "17:30:00.000Z"},
         {team1: 5, team2: 4, time: "18:15:00.000Z"},
         {team1: 7, team2: 6, time: "19:00:00.000Z"},
         {team1: 5, team2: 6, time: "20:00:00.000Z"},
         {team1: 4, team2: 7, time: "20:45:00.000Z"},
      ];

      // Construct matches list with team IDs from selectedTeams
      const matchesList = matches.map(({team1, team2, time}) => ({
         startTime: `${selectedDate}T${time}`, team1: selectedTeams[team1], team2: selectedTeams[team2],
      }));

      // Payload construction
      const payload = {
         estimatedTime: 50,
         ground: "Sydney Cricket Ground",
         matches: matchesList,
         numOfPlayers: 10,
         overs: 10,
         pitchBounciness: 0,
         serverId: parseInt(serverId),
         teams: selectedTeams,
         tournamentId: 1,
      };

      // API request
      fetch("http://34.170.10.55:8080/api/scheduler/matches/multiple/time", {
         method: "POST",
         headers: {
            "Content-Type": "application/json",
         },
         body: JSON.stringify(payload),
      })
         .then((response) => {
            if (response.ok) {
               alert("Data submitted successfully!");
            } else {
               alert("Error: " + response.statusText);
            }
            return response.json();
         })
         .then((data) => console.log("Response from server:", data))
         .catch((error) => console.error("Error:", error));
   }

   useEffect(() => {
      const teams = {
         20001: { name: "Mumbai Indians", img: "https://storage.googleapis.com/meta11-qa/teams/MumbaiIndians.png" },
         20002: { name: "Kolkata Knight Riders", img: "https://storage.googleapis.com/meta11-qa/teams/kkr.png" },
         20003: { name: "Royal Challengers Bengaluru", img: "https://storage.googleapis.com/meta11-qa/teams/rcb.png" },
         20004: { name: "Punjab Kings", img: "https://storage.googleapis.com/meta11-qa/teams/PanjabKings.png" },
         20005: { name: "Lucknow Super Giants", img: "https://storage.googleapis.com/meta11-qa/teams/LucknowSuperGiants.png" },
         20006: { name: "Sunrisers Hyderabad", img: "https://storage.googleapis.com/meta11-qa/teams/srh.png" },
         20007: { name: "Gujarat Titans", img: "https://storage.googleapis.com/meta11-qa/teams/GujaratTitans.png" },
         20008: { name: "Delhi Capitals", img: "https://storage.googleapis.com/meta11-qa/teams/DelhiCapitals.png" },
         20009: { name: "Rajasthan Royals", img: "https://storage.googleapis.com/meta11-qa/teams/rr.png" },
         20010: { name: "Chennai Super Kings", img: "https://storage.googleapis.com/meta11-qa/teams/csk.png" },
      };

      const teamSelectorsContainer = document.getElementById("teamSelectors");

      // Populate team selectors dynamically
      for (let i = 1; i <= 8; i++) {
         const div = document.createElement("div");
         div.className = styles.teamSelector;

         const label = document.createElement("label");
         label.textContent = `Team ${i}`;

         const select = document.createElement("select");
         select.name = `team${i}`;
         select.required = true;

         // Add change event listener to update selected option image
         select.addEventListener('change', function() {
            const selectedTeam = teams[this.value];
            if (selectedTeam) {
               this.style.backgroundImage = `url('${selectedTeam.img}')`;
            }
         });

         // Add empty option as default
         const defaultOption = document.createElement("option");
         defaultOption.value = "";
         defaultOption.textContent = "Select a team";
         defaultOption.style.backgroundImage = `url('https://storage.googleapis.com/meta11-qa/teams/ipl.png')`;
         select.appendChild(defaultOption);

         for (const [id, { name, img }] of Object.entries(teams)) {
            const option = document.createElement("option");
            option.value = id;
            option.textContent = name;
            option.style.backgroundImage = `url('${img}')`;
            select.appendChild(option);
         }

         div.appendChild(label);
         div.appendChild(select);
         teamSelectorsContainer.appendChild(div);
      }
   }, []);

   return (<div className={classNames(styles.SchedulePage, "ps-2 pe-2 ps-xxl-0 pe-xxl-0 min-vh-100")}>
      <div className={classNames(styles.pageWrapper, "d-flex flex-column justify-content-between w-100 min-vh-100")}>
         <div
            className={classNames(styles.ResponceCont, "col-12", "row", "pb-2", "ps-2", "pe-2", "ps-xl-0", "pe-xl-0")}>
            <div className="col-12 p-0 row">
               <NavbarSecondary page="events"/>
            </div>

            <div className="container p-0">
               <div className="row gx-0">
                  <div className="col-md-12 col-xl-12">
                     <div className={classNames(styles.topLeadersRow, "row gx-0")}>
                        <div className="col-md-12">
                           <div className="row gx-0">
                              <div className="col-12 ps-3 pe-3">
                                 <div
                                    className="fc-wrapper matches-radio-wrapper row mb-4 mb-sm-0 d-flex justify-content-start">
                                    <h1 className={'text-center'}>eCSL Scheduler</h1>
                                    <form id="schedulerForm">
                                       <div className={styles.formGroup}>
                                          <label htmlFor="date">Date</label>
                                          <input type="date" id="date" name="date" required/>

                                          <label htmlFor="server" className={'ml-3'}>Stream</label>
                                          <select id="server" name="server" required>
                                             <option value="1">Stream 1</option>
                                             <option value="2">Stream 2</option>
                                          </select>
                                       </div>

                                       <div className={styles.teamSelectorContainer} id="teamSelectors">
                                          {/*Team selectors will be dynamically added here*/}
                                       </div>

                                       <button type="button" className={styles.submitBtn} onClick={sendRequest}>Submit
                                       </button>
                                    </form>

                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                  </div>
               </div>
            </div>
         </div>
         <div className={classNames("w-100")}>
            <Footer/>
         </div>
      </div>
   </div>);
}

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null && state.auth.accessToken !== undefined,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      token: state.auth.accessToken,
      tenantId: state.auth.tenantId
   };
};

export default connect(mapStateToProps)(Schedule);
