import React, {useEffect, useState} from "react";
import Styles from "./CreateNewPassword.module.scss"
import Classnames from "classnames"
import * as UserService from "../../services/UserService"
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import styles from "../Matches/Matches.module.scss";

const CreateNewPassword = () => {
    let navigate = useNavigate();

    const queryParams = new URLSearchParams(window.location.search)
    const token = queryParams.get("token")

    const [newPassword, setNewPassword] = useState("");
    const [newPasswordValid, setNewPasswordValid] = useState(false);
    const [newPasswordError, setNewPasswordError] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordValid, setConfirmPasswordValid] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState("")
    const [resetError, setResetError] = useState("")
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    useEffect(() => {
        const resetCodeResponse = UserService.resetPasswordCodeValidation(token);
        resetCodeResponse.then(
            (response) => {
                if (response.apiStatus != true) {
                    navigate("/restore-expired")
                }
            }
        )
    }, [])
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    const getPasswordStrength = (password) => {
        const regexPattern1 = /^(?!.*\s)(?!.*(.)\1\1\1)(?!.*[_]{2})\S{8,}$/;

        if (password.match(regexPattern1)) {
            const passwordLength = password.length;
            const hasUppercase = /[A-Z]/.test(password);
            const hasLowercase = /[a-z]/.test(password);
            const hasDigit = /\d/.test(password);
            const hasAlpha = /[\W_]/.test(password);
            const hasSpecial = /[^\w\d\s:]/.test(password);
            let score = passwordLength * 2;
            if (hasUppercase) score += 4;
            if (hasLowercase) score += 2;
            if (hasDigit) score += 3;
            if (hasAlpha) score += 4;
            if (hasSpecial) score += 5;
            return Math.min(Math.floor(score / 10), 4);
        } else {
            return 0;
        }


    }
    const passwordStrengthBar = (newPasswordError) => {
        switch (newPasswordError) {
            case "Strong":
                return (
                    <div className={Styles.passwordStrength}>
                        <div className={Styles.strengthBar} style={{backgroundColor: "#00FF00", width: "100%"}}></div>
                        <div className={Styles.strengthLabel}>{t("CREATE_NEW_PASSWORD.STRONG")}</div>
                    </div>
                );

            case "Good":
                return (
                    <div className={Styles.passwordStrength}>
                        <div className={Styles.strengthBar} style={{backgroundColor: "#65CE3FFF", width: "75%"}}></div>
                        <div className={Styles.strengthLabel}>{t("CREATE_NEW_PASSWORD.GOOD")}</div>
                    </div>
                );

            case "Fair":
                return (
                    <div className={Styles.passwordStrength}>
                        <div className={Styles.strengthBar} style={{backgroundColor: "#F8F703FF", width: "50%"}}></div>
                        <div className={Styles.strengthLabel}>{t("CREATE_NEW_PASSWORD.FAIR")}</div>
                    </div>
                );

            case "Weak":
                return (
                    <div className={Styles.passwordStrength}>
                        <div className={Styles.strengthBar} style={{backgroundColor: "#F87E03FF", width: "25%"}}></div>
                        <div className={Styles.strengthLabel}>{t("CREATE_NEW_PASSWORD.WEAK")}</div>
                    </div>
                );

            case "Very weak":
                return (
                    <div className={Styles.passwordStrength}>
                        <div className={Styles.strengthBar} style={{backgroundColor: "#FF0101FF", width: "10%"}}></div>
                        <div className={Styles.strengthLabel}>{t("CREATE_NEW_PASSWORD.VERY_WEAK")}</div>
                    </div>
                );
            default:
                return (<></>)

        }
    }
    const validateFields = (fieldName) => {

        switch (fieldName) {
            case 'password':
                if (getPasswordStrength(newPassword) === 4) {
                    setNewPasswordError("Strong")
                    setNewPasswordValid(true);
                } else if (getPasswordStrength(newPassword) === 3) {
                    setNewPasswordError("Good")
                    setNewPasswordValid(true);
                } else if (getPasswordStrength(newPassword) === 2) {
                    setNewPasswordError("Fair")
                    setNewPasswordValid(true);
                } else if (getPasswordStrength(newPassword) === 1) {
                    setNewPasswordError("Weak")
                    setNewPasswordValid(false);
                } else {
                    setNewPasswordError("Very weak")
                    setNewPasswordValid(false);
                }
                if (newPassword === "") {
                    setNewPasswordError("")
                }
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        setResetError("")
        setNewPasswordError("")
        validateFields("password")
        if (!newPassword) {
            setNewPasswordValid(false)
        }
    }, [newPassword])

    useEffect(() => {
        setResetError("")
        setConfirmPasswordError("")
        if (!confirmPassword) {
            setConfirmPasswordValid(false);
        } else if (
            newPassword !== confirmPassword
        ) {
            setConfirmPasswordValid(false);
            setConfirmPasswordError(`${t("CREATE_NEW_PASSWORD.NEW_PASSWORD_AND_CONFIRMATION_SHOULD_BE_EQUAL")}`)
        } else {
            setConfirmPasswordValid(true);
        }
    }, [confirmPassword, newPassword])
    const resetPassword = () => {
        const ResetPasswordResponse = UserService.resetPassword(
            {
                "password": newPassword,
                "token": token
            }
        );
        ResetPasswordResponse.then(
            (response) => {
                if (response.loginStatus == true) {
                    setResetError("")
                    // props.onAuth(response.accessToken, response.refreshToken, "email", response.user.profilePicURL || "", response.userName, response.user.email, response.sessionExpireTime)
                    navigate("/restore-success")
                } else {
                    setResetError(response.data.message)
                }
            }
        )
    }

  const { t } = useTranslation();
  const [translationsLoaded, setTranslationsLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setTranslationsLoaded(true);
    }, 1000);
  }, []);

  const loadingIndicator = (
    <div className="w-100 text-center">
      <div className={styles.loaderContainer}>
        <div className="lds-facebook">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>);

  if (!translationsLoaded) {
    return loadingIndicator;
  }
    return (
        <React.Fragment>

            <div className="row col-11">
                <div className="row mt-4">
                    <img src="https://storage.googleapis.com/meta11/serviceplatform/M11FantasyBetaLogo.webp" alt=""
                         className={Classnames(Styles.loginLogo, "ms-auto", "me-auto")} style={{width: '197px', height: '54px'}}/>
                </div>

                <div className="ms-auto me-auto p-0" style={{width: "495px"}}>
                    <div className={Classnames(Styles.LogoTitle1, Styles.TopBotGaps)}>
                        <div className={Classnames(Styles.text, "text-center", 'p-0')}>{t("CREATE_NEW_PASSWORD.CREATE_A_NEW")}<br/> {t("CREATE_NEW_PASSWORD.PASSWORD")}</div>
                    </div>
                    <br/>

                    <div className="mt-2 col-8 pb-2 text-center ms-auto me-auto body-text3 color-gray2">
                      {t("CREATE_NEW_PASSWORD.ACCESS_YOUR_META_11_ACCOUNT_BY_CREATING_A_NEW_SECURE_PASSWORD")}
                    </div>

                    <div className="justify-content-start mt-4">
                        <div className={Classnames(Styles.group)}>
                            <input name="name" required autoComplete="new-password" type={showPassword ? 'text' : 'password'}
                                   value={newPassword}
                                   onChange={(e) => setNewPassword(e.target.value)}
                                   className={showPassword ? "" : Styles.asteriskInput}/>
                            <label>{t("CREATE_NEW_PASSWORD.NEW_PASSWORD")}</label>
                            <span className={!showPassword ? Styles.normalEye : Styles.slashEye}
                                  onClick={togglePasswordVisibility}></span>
                        </div>
                      <div className="pb-1"> {passwordStrengthBar(newPasswordError)}</div>
                      <div className={Classnames(Styles.group, "pb-1")}>
                            <input type={showConfirmPassword ? 'text' : 'password'} name="name" required
                                   autoComplete="new-password"
                                   value={confirmPassword} style={{marginTop: "4vh"}}
                                   onChange={(e) => setConfirmPassword(e.target.value)}
                                   className={showConfirmPassword ? "" : Styles.asteriskInput}/>
                            <label>{t("CREATE_NEW_PASSWORD.CONFIRM_NEW_PASSWORD")}</label>
                            <span className={!showConfirmPassword ? Styles.normalConfirmEye : Styles.slashConfirmEye}
                                  onClick={toggleConfirmPasswordVisibility}></span>
                        </div>

                      <div style={{"color": '#ff006a', height: "20px"}} className="w-100 text-left error-text mb-2">
                            {resetError} {confirmPasswordError}
                        </div>
                        <input disabled={!newPasswordValid || !confirmPasswordValid} type="submit"
                               value={t("CREATE_NEW_PASSWORD.RESET_PASSWORD")} onClick={resetPassword}
                               className={Classnames(Styles.LoginButton)}/>


                    </div>

                </div>
            </div>

        </React.Fragment>
    );
};

export default CreateNewPassword;
