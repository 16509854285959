import React from "react";
import {TEMP_DATA} from "../../containers/Stats/TEMP_DATA";
import {BattingStylesEN} from "../../utilities/Enums";

const BattersStatTable = () => {
   return (
      <div className="d-flex align-items-center justify-content-center">
         This is still under development.
      </div>
   )

   // eslint-disable-next-line no-unreachable
   return (
      <table className={'position-relative'}>
         <thead>
         <tr>
            <th>Name</th>
            <th>Batting Style</th>
            <th>Total Runs{' '}
               <i className="fa fa-sort" aria-hidden="true"></i>
            </th>
            <th>Strike Rate{' '}
               <i className="fa fa-sort" aria-hidden="true"></i>
            </th>
            <th>Average Per Match{' '}
               <i className="fa fa-sort" aria-hidden="true"></i>
            </th>
            <th>Total Sixes{' '}
               <i className="fa fa-sort" aria-hidden="true"></i>
            </th>
            <th>Total Fours{' '}
               <i className="fa fa-sort" aria-hidden="true"></i>
            </th>
            <th>Total Matches Played{' '}
               <i className="fa fa-sort" aria-hidden="true"></i>
            </th>
         </tr>
         </thead>
         <tbody>
         {TEMP_DATA.BATTERS?.map((player, index) => (
            <tr key={index} style={{height: "30px"}}>
               <td>{player?.playerName}</td>
               <td>{BattingStylesEN[player?.battingStyle]}</td>
               <td>{player?.playerStat?.runs}</td>
               <td>{player?.playerStat?.strikeRate.toFixed(2)}</td>
               <td>{player?.playerStat?.battingAvg.toFixed(2)}</td>
               <td>{player?.playerStat?.sixes}</td>
               <td>{player?.playerStat?.fours}</td>
               <td>{player?.playerStat?.matches}</td>
            </tr>
         ))}

         </tbody>
      </table>
   )
}

export default BattersStatTable
