import React from 'react'
import {connect} from "react-redux";
import styles from "./StatsNavigate.module.scss";
import classNames from "classnames";
import Styles from "./StatsNavigate2.module.scss";

const StatsNavigate = () => {
   return (
      <div className={classNames(styles.questWrap, 'mb-4', "col-12 col-md-6", "col-xl-12", "p-0", "d-none d-xl-block")} style={{position: "relative"}}>
         <div className={classNames(styles.quizBoardWrapper, styles.FantasyzBoardWrapper, "App")}>
            <div className="chatbothead p-relative">
               <div className={classNames(styles.TitleWrapper)}>
                  <h2>STATISTICS</h2>
               </div>
            </div>

            <div className={classNames(Styles.WinPrizesWrapper)}>
               <div className={classNames(Styles.Tournament)}>
                  <div className={classNames(Styles.NoDataWrapper)}>
                     <div className={classNames(Styles.NoFantasyDataWrapper, 'text-center d-flex flex-column gap-2')}>
                        <a className={classNames(Styles.SeeFantasyPointsCalculation, "bg-transparent border-0 text-white")}
                           href={'/stats?statsType=TEAM'} style={{textDecoration: 'none'}}>
                           View Team Statistics
                        </a>
                        <a
                           className={classNames(Styles.SeeFantasyPointsCalculation, "bg-transparent border-0 text-white")}
                           href={'/stats?statsType=PLAYER'} style={{textDecoration: 'none'}}>
                           View Player Statistics
                        </a>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
      refresh: state.auth.refreshToken,
      Token: state.auth.accessToken,
      userId: state.auth.userId,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      email: state.auth.email
   };
}

export default connect(mapStateToProps)(StatsNavigate)
