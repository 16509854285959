import React, {useEffect, useState} from "react";
import Styles from "./PasswordRestoreSuccess.module.scss"
import Classnames from "classnames"
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const PasswordRestoreSuccess = () => {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const [translationsLoaded, setTranslationsLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setTranslationsLoaded(true);
        }, 1000);
    }, []);

    const loadingIndicator = (
        <div className="w-100 text-center">
            <div style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                <div className="lds-facebook">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>);

    if (!translationsLoaded) {
        return loadingIndicator;
    }
    return (
        <React.Fragment>
            <div className="row col-11">
                <div className="row mt-4">
                    <img src="https://storage.googleapis.com/meta11/serviceplatform/M11FantasyBetaLogo.webp" alt=""
                         className={Classnames(Styles.loginLogo, "ms-auto", "me-auto")} style={{width: '197px', height: '54px'}}/>
                </div>
                <div className="ms-auto me-auto p-0" style={{width: "680px"}}>
                    <div className={Classnames(Styles.CorrectIcon, Styles.TopBotGaps, 'mb-5')}></div>
                    <div>
                        <div className={Classnames("heading2 gradient-color text-center", 'p-0')}>
                            {t("PASSWORD_RESTORE_SUCCESS.YOUR_PASSWORD_HAS")}<br/>
                            {t("PASSWORD_RESTORE_SUCCESS.BEEN_RESTORED")}
                        </div>
                    </div>
                    <br/>
                    <div
                        className={Classnames("mt-2", "col-8", "pb-2", "body-text3 color-gray2 text-center", "ms-auto", "me-auto")}>
                        {t("PASSWORD_RESTORE_SUCCESS.NOW_YOU_CAN_USE_THE_NEW_PASSWORD_TO_LOG_IN_TO_YOUR_META_11_ACCOUNT")}
                    </div>
                    <div className={Classnames(Styles.greenBtn, "justify-content-start mt-5  ms-auto me-auto")}>
                        <input onClick={() => navigate("/login")} type="submit" value={t("PASSWORD_RESTORE_SUCCESS.BACK_TO_LOGIN")}
                               className={Classnames(Styles.LoginButton)}/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PasswordRestoreSuccess;
